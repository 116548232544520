import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

import { LargeP, FlexList, FlexItem } from '../elements'
import BecomeACarrier from '../components/BecomeACarrier'

const MaintenancePage = ({ data }) => {
  const postNode = {
    title: `Maintenance - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Maintenance - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="maintenance" customTitle />

      <ServiceContainer>
        <PageTitle>Maintenance Services</PageTitle>
        <LargeP>
          D&D has a full-service maintenance facility to maintain the best
          operating conditions for our carrier partners.
        </LargeP>
        <p>
          Our service facility is fully equipped to repair all aspects of both
          tractor and trailer. As an asset-based company, we know what it takes
          to keep equipment healthy to minimize mechanical down time. We are
          diligent in our effort to maintain all equipment at the highest safety
          and mechanical standards–keeping you on the road.
        </p>
        <BecomeACarrier />

        <h4>Maintenance Services at a Glance</h4>
        <FlexList>
          <FlexItem>Full Service Maintenance Facility</FlexItem>
          <FlexItem>Shop Personnel on call 24/7</FlexItem>
          <FlexItem>Communications</FlexItem>
          <FlexItem>Tracking & GPS</FlexItem>
          <FlexItem>Equipment Compliance</FlexItem>
          <FlexItem>Tire Program</FlexItem>
        </FlexList>
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default MaintenancePage
